
/**
* @description calculates the date of the day in the week of currentDate that corresponds to this.weekday
* @param currentDate: Date of format yyyy-mm-dd
* @param weekdayIndex: 0 means Sunday, 1 monday,... , 6 saturday
*/
function calculateDateAtWeekday(dateString, weekdayIndex){
  let currentWeekdayIndex = new Date(dateString).getDay(); 
  
  /* if the weekday is right, return the old date */
  if (currentWeekdayIndex == weekdayIndex){
    return dateString
  } else {
    /* if the weekday was changed, change the date of this weekend in this week */

    let daysToBeAdded = weekdayIndex - currentWeekdayIndex;

    let returnDate = new Date(dateString + "T12:00:00Z"); //set Date to the start Date
    returnDate.setDate(returnDate.getDate() + daysToBeAdded); //add number of days to get to the date of the weekday wanted

    return returnDate.toISOString().substring(0,10);
  }
}

function addDays(dateString, numberOfDays) {
  let date = new Date(dateString + "T12:00:00Z") //Time 12:00 is needed to avoid bug in switching days if change to summertime happens
  date.setDate(date.getDate() + numberOfDays);
  return dateToIso(date);
}

function addMonths(monthString, numberOfMonths){
  let date = new Date(monthString + "-01T12:00:00Z") //Time 12:00 is needed to avoid bug in switching days if change to summertime happens
  let newDate = new Date(date.getFullYear(), date.getMonth() + numberOfMonths, 1, 12)
  return newDate.toISOString().substr(0,7);
}

/**
 * @description calculates the dates betwenn a start and end date
 * @returns array of dates (including start and enddate)
 * @param startDate: String of format 'hh:mm' that is the start
 * @param endDate: String of format 'hh:mm' that is the end
 */
function calculateDatesBetweenStartAndEnd(startDate, endDate, datesToIgnore=[]){
  let start = new Date(startDate + "T12:00:00Z");
  let end = new Date(endDate + "T12:00:00Z");
  let currentDate = start;
  let dateArray = [];

  while (currentDate <= end) {
    /* add the date to the array, if it is not in datesToIgnore */
    if(!datesToIgnore.includes(dateToIso(currentDate))){
      dateArray.push(dateToIso(currentDate));
    }
    //add one day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}

function dateToIso(date) {
  return date.toISOString().substr(0,10);
}

function isoToDate(dateString){
  return new Date(dateString);
}

/**
* @description calculates the minutes between a given start and end time
* @param start_time: String of format 'hh:mm' that is the earlier time of the two given ones to calculate the difference from.
* @param end_time: String of format 'hh:mm' that is the later time of the two given ones to calculate the difference from.
* @returns Int. Minutes between start and end time
*/
function calculateTimeBetween(start_time, end_time){
  /* gets two strings of format hh:mm 
  returns the time in minutes between those as int*/

  let start = new Date('2020-12-24T' + start_time + ':00Z');
  let end = new Date('2020-12-24T' + end_time + ':00Z');

  return (end - start) / 60000;
}

/**
 * @description adds the minimum amount of minutes for an event (this.minEventDuration) to a given time
 * @param time: String of format 'hh:mm'. Gives the time where the minutes should be added
 * @param minEventDuration: Number of minutes that should be added
 * @returns String of format 'hh:mm'. Time after the minutes were added to the given time
 */
function addMinutes(time, minEventDuration){
  var oldDate = new Date('2020-12-24T' + time + ':00Z');
  var newDate = new Date(oldDate.getTime() + minEventDuration * 60000);

  if (oldDate.toISOString().substring(0,10) != newDate.toISOString().substring(0,10)){
    throw "If you add " + minEventDuration + "min to " + time + ", the time will be on the next day. This is not allowed."
  }
  
  return newDate.toISOString().substring(11, 16);
}

/**
 * returns the date formated in the standart german way
 * @param {isoDate} string of format 'yyyy-mm-dd'
 * @returns string of format 'dd.mm.yyyy' 
 */
function formatDate(date) {
  return date.substring(8,10) + '.' + date.substring(5,7) + '.' + date.substring(0,4);
}

/**
 * 
 * @param month: string of format yyyy-mm
 * @returns string of format yyyy-mm-dd
 */
function calculateLastDayOfMonth(month) {
  const date = new Date(month);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0, 12);
  return dateToIso(lastDay)
}


export {
  calculateDateAtWeekday,
  addDays,
  addMonths,
  calculateDatesBetweenStartAndEnd,
  calculateTimeBetween,
  addMinutes,
  formatDate,
  calculateLastDayOfMonth,
};
<template>
<div>
  <v-card width="370" class="mx-auto mt-5" flat>

    <Errors v-bind:errors="errors"/>

    <v-card-title>
      Einstellungen {{settings.name}} 
    </v-card-title>
    <v-card-text>
      <p>
        Öffnungszeiten: {{ settings.opening }} - {{ settings.closing }}
      </p>
      <p>
        Ende des nächsten (Halb-) Jahres: {{ settings.endOfPeriod }} <br>
        <span class="text-caption">(Neue Serien werden standartmäfig bis zu diesem Datum angelegt.)</span>
      </p>
      <p>
        Länge einer Nachhilfestunde (in Minuten): {{ settings.minEventDuration }}
      </p>
      <p>
        Räume: 
        <ul>
            <li v-for="room in settings.rooms" v-bind:key="room.id">
                {{room.name}}, kurz: {{room.shortName}}
            </li>
        </ul>
      </p>
      <p>
        Schulfreie Tage: <br>
        <span class="text-caption">(Diese Tage werden beim Anlegen neuer Serien übersprungen.)</span>
        <ul>
            <li v-for="(day, index) in settings.schoolfreeDays" v-bind:key="index">
                {{day | formatDateWithShortWeekday }}
                <v-btn
                  color="error"
                  class="ml-2"
                  depressed
                  x-small
                  @click="deleteSchoolfreeDay(index)"
                  >
                  Löschen
                </v-btn>
            </li>
        </ul>
      </p>

      </v-card-text>
    </v-card>

    <v-card width="370" class="mx-auto mt-5" flat>

      <v-divider/>

      <v-card-title>
        Schulfreie Tage hinzufügen
      </v-card-title>

      <v-card-text>
      <v-form 
      @submit.prevent
      v-model="valid"
      ref="addSchoolfreeDayForm"
      >

      <h3 class="mb-0">Neuer schulfreier Tag</h3>

        <!-- Datumsfeld -->
        <v-text-field 
        type="date" 
        v-model.trim="newSchoolfreeDay"
        append-outer-icon="mdi-plus"
        @click:append-outer="addSchoolfreeDay"
        :rules="dateRule"
        required
        />
      </v-form>

      <h3 class="my-3">Zeitspanne</h3>

      <v-form 
      @submit.prevent
      v-model="valid"
      ref="addSchoolfreePeriodForm"
      >
        <!-- Datumsfeld -->
        <v-text-field 
        type="date" 
        label="Startdatum"
        v-model.trim="schoolfreePeriodStart"
        :rules="dateRule"
        required
        />

        <!-- Datumsfeld -->
        <v-text-field 
        type="date" 
        label="Enddatum"
        v-model.trim="schoolfreePeriodEnd"
        class="mt-n2"
        :rules="dateRule"
        required
        />
      </v-form>

      <v-card-actions class=" pt-0">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        depressed
        small
        :disabled="!valid || schoolfreePeriodStart == '' || schoolfreePeriodEnd == ''"
        @click="addSchoolfreePeriod"
        >
        Zeitspanne hinzufügen
      </v-btn>
    </v-card-actions>
    

    </v-card-text>
  </v-card>
</div>
</template>

<script>
import { auth, institutesCollection } from '@/firebase';
import { calculateDatesBetweenStartAndEnd } from '@/assets/js/timeUtils.js';
import Errors from '@/components/Errors.vue';

export default {
  name: 'InfoSettings',

  components: {
    Errors,
  },

  data() {
    return {
      instituteId: '',
      settings: {},
      newSchoolfreeDay: '',
      schoolfreePeriodStart: '',
      schoolfreePeriodEnd: '',

      errors: [],
      valid: false,

      dateRule: [ //checks if the input is a string of format 'yyyy-mm-dd' and a valid date. Caution! does not test for leap years. The 29.02 is always valid.
        v => /\d{4}-(02-[0-2]\d|(01|03|05|07|08|10|12)-([0-2]\d|3[0-1])|(04|06|09|11)-([0-2]\d|30))/.test(v) || "Gib ein gültiges Datum im Format 'yyyy-mm-dd' an."
      ]
    }
  },
  created() {
    this.getSettings();
  },
  methods: {
 
    /**
     * @description gets the instiute settings (min Duration of an Event, rooms etc) from firestore and setts this.rooms and this.room
     */
    async getSettings(){
      //get instituteRef from currently logged in user
      this.instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId; //needed to add data in other funtions (eg addNewSchoolfreeDay)

      //get institute settings
      try{
        const doc = await institutesCollection.doc(this.instituteId).get();
        this.settings = doc.data();
      } catch(error) {
        this.errors = [];
        this.errors.push({
          text: 'Der Einstellungen konnten nicht aus der Datenbank geladen werden.', 
          type:'firestore',
        })
        throw "Could not find this doument in firestore" + error;
      }
    },

    deleteSchoolfreeDay(index){
       this.settings.schoolfreeDays.splice(index, 1);
       
       /* update schoolfreedays in db */
      institutesCollection.doc(this.instituteId).update({ //write the new Array in firebase
        schoolfreeDays: this.settings.schoolfreeDays,
      })
    },

    addSchoolfreeDay() {
      this.errors = [];
      if(this.valid && this.newSchoolfreeDay != '') {
        if(!this.settings.schoolfreeDays.includes(this.newSchoolfreeDay)){

          let updatedSchoolfreeDays = this.settings.schoolfreeDays;
          updatedSchoolfreeDays.push(this.newSchoolfreeDay); //add the new Day to the schoolfree Days Array
          updatedSchoolfreeDays.sort(); //sort the array

          /* update schoolfreedays in db */
          institutesCollection.doc(this.instituteId).update({ //write the new Array in firebase
            schoolfreeDays: updatedSchoolfreeDays,
          })

          /* reset values of form */
          this.newSchoolfreeDay = '';
          this.$refs.addSchoolfreeDayForm.resetValidation();   //reset form validation
          this.getSettings(); //load the new information

        } else {
          this.errors.push({
            text: 'Dieser Tag ist bereits ein schulfreier Tag.', 
          });
          window.scrollTo(0,0);
        }
      } else {
        this.errors.push({
          text: 'Die Felder sind nicht gültig oder leer.', 
        });
        window.scrollTo(0,0);
      }
    },

    addSchoolfreePeriod(){
      this.errors = [];
      if(this.valid 
        && this.schoolfreePeriodStart != ''  
        && this.schoolfreePeriodEnd != ''
        && this.schoolfreePeriodStart < this.schoolfreePeriodEnd
        ) {
        /* calculate the dates to add */
        let dateRange = calculateDatesBetweenStartAndEnd(this.schoolfreePeriodStart, this.schoolfreePeriodEnd, this.settings.schoolfreeDays);
        
        /* add the dates to the array */
        let updatedSchoolfreeDays = this.settings.schoolfreeDays;
        updatedSchoolfreeDays = updatedSchoolfreeDays.concat(dateRange); //add the new Days to the schoolfree Days Array
        updatedSchoolfreeDays.sort(); //sort the array

        /* update schoolfreedays in db */
        institutesCollection.doc(this.instituteId).update({ //write the new Array in firebase
          schoolfreeDays: updatedSchoolfreeDays,
        })

        /* reset values of form */
        this.schoolfreePeriodStart = '';
        this.schoolfreePeriodEnd = '';
        this.$refs.addSchoolfreePeriodForm.resetValidation();   //reset form validation
        this.getSettings(); //load the new data
        
      } else {
        this.errors.push({
          text: 'Die Felder sind nicht gültig oder leer.', 
        });
        window.scrollTo(0,0);
      }
    }

  }
}
</script>

<style>
/* workarround for bugfix: on iOS the cursor is invisible when first clicking into the field, see https://github.com/vuetifyjs/vuetify/issues/13628 */
.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>